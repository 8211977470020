/* eslint-disable import/first */
import React from "react";

// configs
import Main from "../components/main";
import Header from "../components/header";
import Seo from "../components/seo";

// components
import Footer from "../components/footer";

// sections
import Section from "../components/section";

import {
  HeaderSpacer,
  ArticleTitle,
  ContentTitle,
  Title,
  ArticleInfo,
  ContentInfo,
  Date,
  SubInfo,
  SubSubInfo,
  SubSubInfoBlue,
  SubInfoDescription,
  SubInfoDescriptionUnderline,
} from "../styles/privacidade";

function Privacidade() {
  return (
    <>
      <Seo title="| Privacidade - O melhor da saúde mental" />
      <Main className="z-30">
        <Header />
        <HeaderSpacer />
        <Section>
          <ArticleTitle>
            <ContentTitle>
              <Title>Política de Privacidade e Proteção de Dados</Title>
            </ContentTitle>
          </ArticleTitle>

          <ArticleInfo>
            <ContentInfo>
              <Date>Esta página foi atualizada em 21/11/2021.</Date>
              <SubInfo>1. Introdução</SubInfo>
              <SubInfoDescription>
                Bem-vindo a plataforma Pensamental, a escola de saúde mental que mais cresce no país. No Pensamental, levamos a sua privacidade a sério e queremos ser transparentes explicando para
                você como usamos seus dados pessoais e como você pode geri-los. Esta Política de Privacidade e Proteção de Dados (“Política”) deve ser lida em conjunto com os Termos de Uso e Condições
                (“Termos”).
              </SubInfoDescription>
              <SubInfoDescription>
                O Tratamento de dados pessoais é uma condição para utilizar a plataforma, recursos ou site (“plataforma”) Pensamental, não sendo possível oferecer os benefícios dos nossos serviços,
                promoções e acesso à conteúdos gratuitos sem ter acesso aos seus dados pessoais.
              </SubInfoDescription>
              <SubInfoDescription>
                Nós do Pensamental sabemos que seus dados pessoais (“Dados”) são bens valiosos e devem ser mantidos com segurança. Por isso, nesta Política, vamos nos esforçar para deixar bem claro
                como e porque utilizamos seus dados.
              </SubInfoDescription>

              <SubInfo>2. Principais conceitos</SubInfo>
              <SubSubInfo>2.1. O que são dados pessoais?</SubSubInfo>
              <SubInfoDescription>
                Dados Pessoais são informações que identificam uma pessoa natural, exemplo: e-mail, CPF, telefone e endereço. Quando os Dados Pessoais são sobre origem racial ou étnica, convicção
                religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico,
                quando vinculado a uma pessoa natural são considerados Dados Pessoais Sensíveis, e precisam ser tratados com ainda mais cautela pelas empresas.
              </SubInfoDescription>
              <SubSubInfo>2.2. O que são cookies?</SubSubInfo>
              <SubInfoDescription>
                Durante a navegação na internet quando você acessa sites de mídias sociais, notícias, lojas virtuais entre outros, eles podem enviar pequenos arquivos de texto (cookies) para o seu
                navegador para registrar o seu comportamento. Entre outros objetivos, eles servem para identificar o usuário e suas preferências, ajustar o idioma uma vez escolhido por você, mede e
                analisa a atividade no site para melhorias de navegação, exibe anúncios de acordo com o perfil especificado pelo usuário.
              </SubInfoDescription>
              <SubSubInfo>2.3. O que é a LGPD?</SubSubInfo>
              <SubInfoDescription>
                LGPD é a sigla da Lei Geral de Proteção de Dados (Lei nº 13.709/2018). A LGPD estabelece regras sobre coleta, armazenamento e compartilhamento de dados pessoais, atribuindo e
                garantindo a proteção de dados pessoais dos cidadãos brasileiros dentro e fora do Brasil.
              </SubInfoDescription>

              <SubInfo>3. Como e quais Dados coletamos?</SubInfo>
              <SubSubInfo>3.1. Pelo seu cadastro na plataforma Pensamental</SubSubInfo>
              <SubInfoDescription>
                Quando você criar um usuário na plataforma de cursos (período gratuito ou não), nós perguntamos seu nome completo, e-mail, CPF, telefone celular, endereço completo, data de nascimento,
                dados de cartão de crédito e criação de login e senha. Além disso, perguntamos a você se deseja incluir a sua foto para personalizar a sua área de cliente, mas este item não é
                obrigatório.
              </SubInfoDescription>
              <SubSubInfo>3.2. Pela navegação na plataforma</SubSubInfo>
              <SubInfoDescription>
                Quando você navega em nossa plataforma, preenche formulários, participa de promoções e eventos online e demais interações que possa vir a fazer em nossos serviços. Também quando você
                acessa por dispositivos diferentes como: notebook, computador, celular ou outro disposto de acesso. Os Dados que coletamos neste caso são: sua localização aproximada (latitude e
                longitude), seu endereço de IP, informações do seu dispositivo de acesso (tipo de sistema operacional, modelo do aparelho), informação da sua conexão de internet; tipo do seu navegador
                e as páginas e conteúdos que você acessa em nossos Serviços.
              </SubInfoDescription>
              <SubSubInfo>3.3. Por meio de terceiros</SubSubInfo>
              <SubInfoDescription>Podemos também utilizar alguns dos seus Dados através de fontes disponíveis ao público, prestadores de serviços e nossos parceiros.</SubInfoDescription>
              <SubSubInfo>3.4. Por meio de cookies</SubSubInfo>
              <SubInfoDescription>Através do uso de cookies, identificamos seus hábitos de visita, suas preferências, entrega de anúncios e análises de audiências.</SubInfoDescription>
              <SubSubInfoBlue>- Quais as categorias e a finalidade dos cookies?</SubSubInfoBlue>
              <SubInfoDescriptionUnderline>Estritamente necessários/técnicos</SubInfoDescriptionUnderline>
              <SubInfoDescription>
                Necessários para o funcionamento da plataforma, sem eles torna-se impossível executar algumas funcionalidades da plataforma. Eles permitem que você navegue e use os serviços e recursos
                (por exemplo, cookies de segurança para autenticar usuários, evitar a utilização fraudulenta de credenciais de login e proteger os Dados do usuário de terceiros não autorizados).
                Portanto, não é possível recusar estes cookies se você quiser navegar e consumir nossos conteúdos.
              </SubInfoDescription>
              <SubInfoDescriptionUnderline>Analíticos/Desempenho</SubInfoDescriptionUnderline>
              <SubInfoDescription>
                Coletam informações de forma anônima, ou seja, os resultados que recebemos dessa análise são fornecidos de forma agregada e não permitem a identificação direta ou indireta do Usuário.
                Esses cookies permitem determinar informações, como o número de visitantes de uma página, como os visitantes chegaram ao site e as páginas acessadas. Por exemplo, esses cookies
                rastreiam as páginas que são visitadas com maior frequência e a localidade de nossos visitantes. Esses cookies incluem, por exemplo, os cookies do Google Analytics.
              </SubInfoDescription>
              <SubInfoDescriptionUnderline>Funcionalidade</SubInfoDescriptionUnderline>
              <SubInfoDescription>
                Os cookies desta categoria permitem ao Pensamental operar a plataforma com as escolhas que você faz. Eles nos permitem “lembrar” de suas visitas anteriores a plataforma. Por exemplo,
                reconheceremos o seu nome de usuário e lembramos como você personalizou o seu conteúdo dentro da plataforma, nos possibilita oferecer a você, sempre que você retornar a plataforma, as
                mesmas personalizações realizadas anteriormente.
              </SubInfoDescription>
              <SubInfoDescriptionUnderline>Publicidade e direcionamento</SubInfoDescriptionUnderline>
              <SubInfoDescription>
                Esses cookies coletam informações sobre as suas atividades na plataforma e em outros sites para fornecer publicidade segmentada. Podemos também permitir que os nossos prestadores de
                serviços terceirizados utilizem cookies na plataforma para os mesmos fins identificados acima. Os prestadores de serviços terceirizados que geram esses cookies, como: Google, Facebook,
                entre outros, possuem as suas próprias políticas de privacidade e podem utilizar os seus cookies para segmentar publicidade para você em outros sites, com base na sua visita a nossa
                plataforma.
              </SubInfoDescription>
              <SubSubInfoBlue>- Utilização de cookies de terceiros</SubSubInfoBlue>
              <SubInfoDescription>
                Prestadores de serviços, com a nossa autorização, poderão utilizar seus próprios cookies nos Serviços. Tais cookies coletarão os seus Dados nas nossas propriedades para as finalidades
                previstas nesta política.
              </SubInfoDescription>
              <SubInfoDescription>
                Os “cookies de terceiros” são cookies que terceiros inserem no seu dispositivo através da nossa plataforma, com a finalidade de nos ajudar a medir e aperfeiçoar a eficácia de nossas
                comunicações. Para maiores informações sobre como essas empresas coletam e utilizam as informações em nosso nome, consulte as respectivas políticas de privacidade em: Google e
                Facebook.
              </SubInfoDescription>
              <SubSubInfoBlue>- Quais cookies que utilizamos?</SubSubInfoBlue>
              <SubInfoDescription>
                <b>Nome: Active Campaign</b>
                <br />
                Fornecedor: Active Campaign
                <br />
                Finalidade: Mail Marketing <br />
                Validade: 60 dias para os usuários que não tiverem nenhuma interação.
                <br />
                Tipo: Marketing
              </SubInfoDescription>
              <SubInfoDescription>
                <b>Nome: Facebook Ads</b>
                <br />
                Fornecedor: Facebook <br />
                Finalidade: Mail Marketing <br />
                Validade: Não temos dados individualizados.
                <br />
                Tipo: Marketing
              </SubInfoDescription>
              <SubInfoDescription>
                <b>Nome: Google Ads</b>
                <br />
                Fornecedor: Google <br />
                Finalidade: Anúncios <br />
                Validade: Não temos dados individualizados.
                <br />
                Tipo: Marketing
              </SubInfoDescription>
              <SubInfoDescription>
                <b>Nome: Google Analytics</b>
                <br />
                Fornecedor: Google <br />
                Finalidade: Estatística <br />
                Validade: Não temos dados individualizados.
                <br />
                Tipo: Estatística
              </SubInfoDescription>
              <SubInfoDescription>
                <b>Nome: Google Tag Manager</b>
                <br />
                Fornecedor: Google <br />
                Finalidade: Estatística <br />
                Validade: Não temos dados individualizados.
                <br />
                Tipo: Estatística
              </SubInfoDescription>
              <SubSubInfoBlue>- Como faço para alterar ou bloquear cookies?</SubSubInfoBlue>
              <SubInfoDescription>
                Os navegadores normalmente estão configurados para aceitar os cookies. Porém, você pode alterar estas configurações no momento que desejar para bloquear ou alertá-lo quando um cookie
                estiver sendo enviado ao seu dispositivo.
              </SubInfoDescription>
              <SubInfoDescription>
                Existem várias formas de gerenciar cookies, você pode bloquear cookies de um site específico, bloquear cookies de terceiros em relação a um site, ou ainda bloquear todos os cookies.
                Mas fique atento, o bloqueio de todos os cookies irá afetar o funcionamento da sua experiência, pois não será possível identificar suas preferências na plataforma e nem recomendar
                conteúdo e publicidade relevante.
              </SubInfoDescription>
              <SubInfoDescription>
                Para saber mais sobre os ajustes de cookies, consulte as instruções do seu navegador, lembrando que você deve fazer essa configuração escolhida em todos os dispositivos utilizados para
                acessar os Serviços (como computadores, smartphones, tablets).
              </SubInfoDescription>
              <SubInfoDescription>Consulte as respectivas configurações dos nos principais navegadores em: Internet Explorer, Edge, Chrome, Safari, Firefox e Opera.</SubInfoDescription>

              <SubInfo>4. Para quais finalidades usamos os seus Dados?</SubInfo>
              <SubSubInfo>4.1. Fornecer Serviços personalizados a você</SubSubInfo>
              <SubInfoDescription>
                Coletamos seus Dados para que possamos entregar conteúdo, processar pagamentos e direcionar Serviços do seu interesse. Seus Dados nos ajudam a criar uma experiência personalizada de
                acordo com seus interesses.
              </SubInfoDescription>
              <SubSubInfo>4.2. Nos comunicar com você de forma diferenciada</SubSubInfo>
              <SubInfoDescription>
                Coletamos seus Dados para aprimorar nossos Serviços, melhorar nosso atendimento técnico e operacional respondendo o mais breve possível suas solicitações, quando você entrar em contato
                conosco.
              </SubInfoDescription>
              <SubSubInfo>4.3. Aumentar a segurança e proteção dos seus direitos</SubSubInfo>
              <SubInfoDescription>Alguns Dados são mantidos armazenados para prevenir atividades ilegais e suspeitas.</SubInfoDescription>
              <SubSubInfo>4.4. Aprimorar e desenvolver novos serviços</SubSubInfo>
              <SubInfoDescription>Utilizamos seus Dados em pesquisas e análises de dados (inteligência analítica) para aprimorar e oferecer novos serviços.</SubInfoDescription>
              <SubSubInfo>4.5. Cumprir obrigações legais</SubSubInfo>
              <SubInfoDescription>Mantemos seus Dados para que, caso haja necessidade, possamos cumprir obrigações legais e regulatórias judiciais ou administrativas.</SubInfoDescription>

              <SubInfo>5. Como tratamos Dados de crianças?</SubInfo>
              <SubInfoDescription>
                Nós buscamos não coletar dados de crianças (indivíduos menores de 13 anos). A idade mínima para utilizar a nossa plataforma, participar de promoções e ofertas é de 13 anos. Antes do
                armazenamento de dados, solicitamos para validação de idade, no momento do cadastro, o CPF e a data de nascimento do usuário.
              </SubInfoDescription>

              <SubInfo>6. Com quem compartilhamos seus Dados</SubInfo>
              <SubSubInfo>6.1. Quais empresas do Grupo “Pensamental” controlam os seus Dados</SubSubInfo>
              <SubInfoDescription>
                As empresas do grupo Pensamental compartilham entre si infraestrutura, sistemas e tecnologia para que você tenha sempre uma experiência integrada e um mesmo padrão de atendimento em
                todos os Serviços oferecidos.
              </SubInfoDescription>
              <SubInfoDescription>Seus Dados são controlados pelas empresas listadas abaixo:</SubInfoDescription>
              <SubInfoDescription>Pensamental LTDA (“Pensamental“)</SubInfoDescription>
              <SubSubInfo>6.2. Compartilhamos com terceiros</SubSubInfo>
              <SubInfoDescription>
                Compartilhamos seus Dados com alguns de nossos parceiros que nos ajudam na execução dos nossos Serviços, como parceiros: provedores e integradores de meios de pagamento, empresas de
                cartão de crédito, prestadora de serviços de marketing e publicidade, autoridades judiciais, policiais ou governamentais, prestadores de serviços para manutenção de nossa plataforma,
                análise de dados visando o enriquecimento da base de dados para personalização e melhoria dos serviços. Todos os prestadores de serviços estão contratualmente obrigados ao sigilo, bem
                como a respeitar a Lei aplicável e nos termos de nossa Política.
              </SubInfoDescription>

              <SubInfo>7. Hiperlinks para outros sites da internet</SubInfo>
              <SubInfoDescription>
                Nossa plataforma poderá conter links de acesso a outros sites da internet (hiperlinks). Lembramos que nossa Política se aplica apenas a nossa plataforma de acesso aos nossos Serviços.
                Recomendamos que ao clicar em link para outro site, você leia a política de privacidade deles.
              </SubInfoDescription>

              <SubInfo>8. Como protegemos os seus Dados?</SubInfo>
              <SubInfoDescription>Utilizamos padrões técnicos de mercado para manter a segurança e privacidade dos seus Dados.</SubInfoDescription>
              <SubInfoDescription>
                Possuímos ações abrangentes em tecnologia e processos organizacionais. Adotamos medidas para preservar seus Dados contra acesso, alteração, uso e destruição não autorizada, incluindo:
                gestão de acesso, gestão de ativos físicos, comunicações criptografadas e políticas internas de cibersegurança.
              </SubInfoDescription>

              <SubInfo>9. Você no controle – Seus direitos de usuário</SubInfo>
              <SubInfoDescription>
                A qualquer momento você pode decidir sobre a atualização e o tratamento que poderemos dar aos seus Dados. Você pode, de forma gratuita, revogar um consentimento dado anteriormente,
                corrigir, atualizar ou excluir seus Dados.
              </SubInfoDescription>
              <SubInfoDescription>
                Para isso, caso deseje realizar alguma das ações anteriores, ou mesmo se desejar apenas entender mais, tirar dúvida ou confirmar a existência de algum tratamento, entre em contato com
                o nosso time de privacidade através do e-mail{" "}
                <a className="text-blue-pensamental-light hover:underline" href="mailto:contato@pensamental.com">
                  contato@pensamental.com
                </a>
                .
              </SubInfoDescription>
              <SubInfoDescription>
                Caso você solicite a atualização, correção ou mesmo a exclusão dos seus Dados, o Pensamental atenderá a solicitação em até 15 dias. Após a exclusão, nós poderemos manter os seus Dados
                somente (a) pelo prazo necessário para fins de processos judiciais, administrativos e arbitrais, (b) para cumprimento de obrigação legal e/ou regulatória, (c) para o exercício regular
                de direitos, como, por exemplo, fazer valer os direitos com base no contrato de assinatura e/ou Termos aplicáveis ou (d) em formato anonimizado.
              </SubInfoDescription>
              <SubInfoDescription>
                Armazenamos seus dados durante todo o período que você navegar na nossa plataforma e utilizar nossos serviços, ou se for um potencial cliente e quiser acompanhar nossas novidades. Caso
                você fique inativo na nossa plataforma por um período de 1(um) ano a contar da última visita que você nos fizer, nos excluiremos seus dados do nosso baco de dados.
              </SubInfoDescription>

              <SubInfo>10. Transferência Internacional de Dados</SubInfo>
              <SubInfoDescription>
                Para oferecer os nossos serviços, alguns de seus Dados poderão ser transferidos para outros países, essa transferência ocorrerá quando utilizarmos serviços em nuvem para processamento
                ou armazenamento de dados, localizados fora do Brasil. Fique tranquilo, nestas situações também estaremos atendendo todos os requisitos estabelecidos na Lei vigente e nesta Política,
                além de adorar as melhores práticas de mercado a fim de garantir a proteção e privacidade dos seus Dados.
              </SubInfoDescription>

              <SubInfo>11. Contato</SubInfo>
              <SubInfoDescription>Somos todos ouvidos. Entre em contato conosco caso tenha algum pedido, dúvida ou sugestão com relação a nossa Política.</SubInfoDescription>
              <SubInfoDescription>
                Equipe de suporte -{" "}
                <a className="text-blue-pensamental-light hover:underline" href="mailto:contato@pensamental.com">
                  contato@pensamental.com
                </a>
              </SubInfoDescription>
              <SubInfoDescription>Ou acesse a seção de perguntas frequentes em nossa plataforma ou ainda acione diretamente na plataforma o chat do suporte.</SubInfoDescription>
            </ContentInfo>
          </ArticleInfo>
        </Section>

        <Footer isDarkest />
      </Main>
    </>
  );
}

export default Privacidade;
