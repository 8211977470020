import tw from "tailwind-styled-components";

import styled from "styled-components";

export const HeaderSpacer = styled.div`
  width: 100%;
  height: 75px;
  background-color: transparent;
`;

export const ArticleTitle = tw.article`
  w-full
  flex 
  flex-col
  justify-center
  items-center
  bg-pensamental-dark
  px-10
  pb-4 md:pb-4
  pt-2 md:pt-2
`;

export const ContentTitle = tw.div`
  max-w-screen-md
`;

export const Title = tw.h1`
  font-baloo
  text-4xl
  text-white
  text-left
`;

export const ArticleInfo = tw.article`
  w-full
  flex 
  flex-col
  justify-center
  items-center
  bg-white
  px-10
  py-10 md:py-12
`;

export const ContentInfo = tw.div`
  text-center
  w-full
  max-w-screen-md
  flex
  flex-col
`;

export const Date = tw.p`
  font-comfortaa
  text-md
  text-gray-700
  text-left
`;

export const SubInfo = tw.h2`
  mt-6
  font-baloo
  text-4xl
  text-blue-pensamental-dark
  text-left
  mb-4
`;

export const SubSubInfo = tw.h5`
  mt-2
  font-comfortaa
  font-extrabold
  text-md
  text-gray-700
  text-left
  mb-4
`;

export const SubSubInfoBlue = tw.p`
  font-comfortaa
  text-md
  text-blue-pensamental-light
  text-left
  mt-6
  mb-4
`;

export const SubInfoDescription = tw.p`
  font-comfortaa
  text-md
  text-gray-700
  text-left
  mb-4
`;

export const SubInfoDescriptionUnderline = tw.p`
  underline
  font-comfortaa
  text-md
  text-gray-700
  text-left
  mb-4
`;
